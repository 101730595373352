<template>
    <div class="user-form">
        <img class="mobile-logo" :src="logo" />
        <div class="form-title">沃享佣推荐返佣管理后台</div>
        <el-form :style='{width: formW }' :model="formData" :rules="formRules" ref="formData" label-width="70px">
            <img class="logo" :src="logo" />
            
            <el-form-item
                v-for="(item, index) in formItems"
                :key="index"
                :label="item.label" 
                :prop="item.key"
                :label-width="item.label ? '70px' : '0px'"
            >
                <el-input 
                    v-if="item.type == 'input'" 
                    v-model="formData[item.key]" 
                    :placeholder="item.placeholder"
                    :style="{width: (item.inputW || '')}"
                    :show-password="item.isPassword"
                ></el-input>
               <slot v-if="item.slotName" :name="item.slotName"></slot>

            </el-form-item>
            <el-form-item v-if="formMsg.length" :label-width="isLabel ? '70px' : '0px'">
                <div class="fomr-msg">
                    <div
                        v-for="(item, index) in formMsg"
                        :key="index"
                    >
                        {{item.label}}
                        <span @click="handleMsg(item.callbackName)">{{item.title}}</span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item :label-width="isLabel ? '70px' : '0px'">
                <el-button 
                    style="width:100%;"
                    type="primary" 
                    @click="onSubmit"
                >
                    {{butText}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        formData: {
            type: Object,
            default: () => {}
        },
        formItems: {
            type: Array,
            default: () => []
        },
        formRules: {
            type: Object,
            default: () => {}
        },
        formMsg: {
            type: Array,
            default: () => []
        },
        butText: {
            type: String,
            default: '重置密码'
        },
        isLabel: {
            type: Boolean,
            default: true
        },
        formW: {
            type: String,
            default: '440px'
        }
    },
    data() {
        return {
            logo: require('@/assets/image/pc_logo.png')
        };
    },
    created() {
        this.$nextTick(() => {
            const t = this.$refs.formData.validateField
            this.$store.commit('SET_REF_FORM', t)
        })
    },
    methods: {
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if(valid) {
                    this.$emit('onSubmit',this.formData)
                }
            })
        },
        handleMsg(name) {
            this.$emit('handleMsg',name)
        }
    },
};
</script>

<style lang="scss" scoped>
.user-form{
    ::v-deep .el-form{
        box-shadow: 0px 15px 50px rgba(61, 53, 84, 0.08);
        background: #fff;
        margin: auto;
        border-radius: 4px;
        padding: 32px 40px 40px 40px;
        .logo{
            display: block;
            @include wh(185px, 58px);
            margin: auto;
            margin-bottom: 32px;
        }
        .el-form-item:last-child{
            margin-bottom: 0;
        }
    }
    .mobile-logo{
        display: none;
        @include wh(185px, 58px);
        margin: auto;
        margin-bottom: 24px;
    }
    .form-title{
        @include font(48px,#252631,center);
        margin-bottom: 42px;
    }
    .fomr-msg{
        @include flex(center,space-between);
        @include font(16px,#C4CAD9);
        @media screen and (max-width:1024px){
            @include font(14px);
        }
        >div{
            @include flex();
            span{
                color: $main_color;
                cursor: pointer;
            }
        }
    }
    ::v-deep .el-button{
        &.el-button--primary{
            background: $main_color;
            border-color: $main_color;
        }
    }
}
@media screen and (max-width:1024px){
    .user-form{
        ::v-deep .el-form{
            width: 100% !important;
            box-shadow: inherit;
            height: calc(100vh - 230px);
            border-radius: 0px;
            .logo{
                display: none;
            }
        }
        .mobile-logo{
            display: block;
        }
        .form-title{
            font-size: 24px;
        }
    }
}
@media screen and (max-width:375px) {
    .user-form{
        .form-title{
            font-size: 22px;
        }
    }
}
@media screen and (max-width:320px) {
    .user-form{
        .mobile-logo{
           @include wh(196px, 50px);
        }
        .form-title{
            font-size: 18px;
        }
    }
}
</style>
